<template>
<v-dialog v-model="contactDialog" 
         style="transform: scale(0.875);transform-origin: left;"
         overlay-color="red"
         :fullscreen="$vuetify.breakpoint.mobile">
 <v-form v-model="valid">
  <v-card class="white text--black pa-4"> 
   <v-row>
     <v-col cols="12">
      <div class="text-center"> <h2>Sipress/Kovsiewas Contact Form</h2> </div>
     </v-col>
     <v-col cols="8" md="4">
      <v-text-field outlined v-model="name" label="Name" type="name" :rules="nameRules" required />
     </v-col>
     <v-col cols="8" md="4">
      <v-text-field outlined v-model="email"  :rules="emailRules" type="email" label="Email" required />
     </v-col>
     <v-col cols="8" md="4">
      <v-text-field outlined v-model="phone" label="Phone" type="tel" />
     </v-col>

     <v-col cols="8" md="4" class="px-3 py-0">
      <v-radio-group v-model="radioGroup" label="Enquiry" >
      <v-radio
        v-for="n in ['Scholar','Student','Household','General']"
        :key="n"
        :label="`${n}`"
        :value="n"
      ></v-radio>
      </v-radio-group>
     </v-col>
     <v-col cols="8">
        <v-textarea outlined v-model="message" label="Message" />
     </v-col>
     <v-card-actions>
        <v-btn color="green" 
              @click="sendMail"> 
          <v-icon>mdi-hanger</v-icon>
          Submit your Email 
        </v-btn>
        <v-spacer />
        <v-btn  @click="contactDialog = false"> Cancel the Email </v-btn>
     </v-card-actions>
   </v-row>
  </v-card>
 </v-form>
</v-dialog>
</template>

<script>
import { zmlMail } from '@/api/zmlMail';
import { zmlConfig } from '@/api/constants';
export default {
  name: "ContactForm",
  data: () => ({
      valid: false,
      contactDialog:false,
      name:'',
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 20 || 'Name must be less than 20 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      phone: '',
      radioGroup: 'Scholar',
      message: 'Dear Zi-Mari, I have heard great things about KovsieWas, and would like to join the family!',
    }),   
    methods:{
        open() {
          this.contactDialog = true;
        },
        sendMail() {
          if (this.valid == false) {
            alert('Some fields are not filled in properly!');
          } else {
            let heading = "<h2> Email Enquiry from KovsieWas Website</h2>";
            heading += "<div><table><tr><td>Phone:<td>" + this.phone;
            heading += "<tr><td>Name:<td>" + this.name;
            heading += "<tr><td>Email:<td>" + this.email;
            heading += "<tr><td>Enquiry:<td>" + this.radioGroup;
            heading += "<tr><td>Message:<td>";
            let signature = '<table border=0><tr><td style="border-bottom: 1px solid blue;border-top: 1px solid blue;font-weight:bold;text-align:left;font-size:20pt;color:blue">Zi-Mari Smit<p style="font-weight:bold;text-align:left;font-size:10pt;color:blue">072 826 0575<td style="border-bottom: 1px solid blue;border-top: 1px solid blue;"><br><Font STYLE="font-size:26pt; color:red; line-height:1.69cm;font-weight:bold">S</font><FONT STYLE="font-weight:bold;text-align:left;font-size:20pt;color:red">ipress</font><font style="font-family:Arial Black,Sans-Serif;font-size=10pt;color:red"><a href=https://sipress.co.za>Wassery </a></font></td></table>';
            let ts = {method: "zmlsendmail"
                     ,htmlmessage: heading + this.message + "</table></div><br><br>" + signature
                     ,email_to: [this.email,'kovsiewas@sipress.co.za','sipresswas@gmail.com']
                     ,subject:"Navraag aan Sipress wassery website / Enquiry to Sipress.co.za / KovsieWas"
                     ,email_from: "kovsiewas@sipress.co.za"
                     ,attachments: []
                    };
            zmlMail(ts,this.emailSend);
         }
        },
        emailSend(response) {
          if (response.errorcode == 0) {
             this.contactDialog = false;  
             this.message = '';
             this.email = '';
             this.phone = '';
          } else {
             alert(response);
             zmlConfig.cl(response);
          }

        }
    },
    mounted: function () { 
      zmlConfig.cl('CFmount : ', this.$options.name , 'c=',this.$children.length);
    }
}
</script>